import REST from "../utils/rest";

export async function checkIfLogged() {
    const [success, result] = await REST.GET(`is_logged`, { bypasAuth: true })
    //console.log('success, result', { success, result })
    if (!success || !result)
        return null;
    return result
}

export async function login(username, password, rfid, redirect) {
    const [success, result] = await REST.POST(`login`, {
        login: username,
        passwd: password,
        rfid: rfid,
        redirect: redirect
    }, { bypasAuth: true },)
    if (!success) return null;
    return result;
}

export async function logOut() {
    const [success] = await REST.GET(`logout`)
    return success;
}
